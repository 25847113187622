import { mobileApplicationRoutePath } from '@bcf-v2/routes-landings/common/app/paths/mobile-application-route-path';
import { careersRoutePath } from '@bcf-v2/routes-landings/sportsbook/app/paths/careers-route-path';
import { aboutUsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/about-us-route-path';
import { cooperationRoutePath } from '@bcf-v2/routes-statics/common/app/paths/cooperation-route-path';
import { faqRoutePath } from '@bcf-v2/routes-statics/common/app/paths/faq-route-path';
import { localsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/locals-route-path';
import { pressRoutePath } from '@bcf-v2/routes-statics/common/app/paths/press-route-path';
import { promotionsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/promotions-route-path';
import { responsibleGamingRoutePath } from '@bcf-v2/routes-statics/common/app/paths/responsible-gaming-route-path';
import { sitemapSimplePageRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sitemap-simple-page-route-path';
import { supportRoutePath } from '@bcf-v2/routes-statics/common/app/paths/support-route-path';
import { termsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/terms-route-path';
import { vipClubRoutePath } from '@bcf-v2/routes-statics/common/app/paths/vip-club-route-path';
import { cashoutRoutePath } from '@bcf-v2/routes-statics/sportsbook/app/paths/cashout-route-path';
import { privacyPolicyRoutePath } from '@bcf-v2/routes-statics/sportsbook/app/paths/privacy-policy-route-path';
import { betOnActionRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/bet-on-action-route-path';
import { bpBetBuilderRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/bp-bet-builder-route-path';
import { esportRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/esport-route-path';
import { liveBettingRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/live-betting-route-path';
import { playerBetsRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/player-bets-paths';
import { preMatchesRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/pre-matches-route-path';
import { resultsCenterRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/results-center-route-path';
import { virtualGamesRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/virtual-games-route-path';
import { virtualSportsRoutePath } from '@bcf-v2/routes/sportsbook/app/paths/virtual-sports-route-path';
import { FooterLink } from '../types';

export const footerLinksFn = (): FooterLink[][] => [
  [
    {
      url: aboutUsRoutePath(),
      title: $localize`About us`
    },
    {
      url: localsRoutePath(),
      title: $localize`Locals`
    },
    {
      title: $localize`Check bet`,
      modalFragmentLink: 'check-bet'
    },
    {
      url: pressRoutePath(),
      title: $localize`Press`
    },
    {
      url: careersRoutePath(),
      title: $localize`Careers`
    },
    {
      url: 'https://lvbet.pl/blog/',
      title: $localize`Blog`
    },
    {
      url: resultsCenterRoutePath(),
      title: $localize`Results`
    },
    {
      url: cooperationRoutePath(),
      title: $localize`Cooperation`
    }
  ],
  [
    {
      url: preMatchesRoutePath(),
      title: $localize`Sports`
    },
    {
      url: liveBettingRoutePath(),
      title: $localize`Live betting`
    },
    {
      url: esportRoutePath(),
      title: $localize`Esport`
    },
    {
      url: virtualGamesRoutePath(),
      title: $localize`Games`
    },
    {
      url: promotionsRoutePath(),
      title: $localize`Bonuses`
    },
    {
      url: 'https://sports-stats.lvbet.pl/scoreframe/pl/soccer/events',
      title: $localize`Statistics`
    }
  ],
  [
    {
      url: bpBetBuilderRoutePath(),
      title: $localize`Betbuilder`
    },
    {
      url: virtualSportsRoutePath(),
      title: $localize`Virtual sports`
    },
    {
      url: playerBetsRoutePath(),
      title: $localize`Player Bets`,
      bottomSheetKind: 'dst-player-bets'
    },
    {
      url: betOnActionRoutePath(),
      title: 'LV Jackpot'
    },
    {
      url: mobileApplicationRoutePath(),
      title: $localize`Mobile application`
    },
    {
      url: cashoutRoutePath(),
      title: $localize`Cashout`
    },
    {
      url: vipClubRoutePath(),
      title: $localize`Vip Club`
    }
  ],
  [
    {
      url: supportRoutePath(),
      title: $localize`Support`
    },
    {
      url: termsRoutePath(),
      title: $localize`Terms and conditions`
    },
    {
      url: faqRoutePath(),
      title: $localize`FAQ`
    },
    {
      url: responsibleGamingRoutePath(),
      title: $localize`Responsible gaming`
    },
    {
      url: privacyPolicyRoutePath(),
      title: $localize`Privacy Policy`
    },
    {
      url: sitemapSimplePageRoutePath(),
      title: $localize`Sitemap`
    }
  ]
];
