import { Provider } from '@angular/core';
import { forceBackToCurrentLocation } from '@bcf-v2-configurators/tokens/cashier/mobile-web-twa/force-back-to-current-location';
import { FORCE_BACK_TO_CURRENT_LOCATION } from '@bcf-v2-configurators/tokens/cashier/token';
import { cookieConsentDisabled } from '@bcf-v2-configurators/tokens/cookie-consent/projects/sportsbook/lvbetpl/mobile-twa-cookie-consent-disabled';
import { COOKIE_CONSENT_DISABLED } from '@bcf-v2-configurators/tokens/cookie-consent/token';
import { commonTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/lvbetpl/common-tax-payout-details-info';
import { customTaxPayoutDetailsInfo } from '@bcf-v2-configurators/tokens/my-bets/lvbetpl/custom-tax-payout-details-info';
import {
  COMMON_TAX_PAYOUT_DETAIL_INFO,
  CUSTOM_TAX_PAYOUT_DETAIL_INFO
} from '@bcf-v2-configurators/tokens/my-bets/token';
import { shareBetHost } from '@bcf-v2-configurators/tokens/share-bet/lvbetpl/share-bet-host';
import { SHARE_BET_HOST } from '@bcf-v2-configurators/tokens/share-bet/token';

export const settingsProviders: Provider[] = [
  {
    provide: COOKIE_CONSENT_DISABLED,
    useValue: cookieConsentDisabled
  },
  {
    provide: FORCE_BACK_TO_CURRENT_LOCATION,
    useValue: forceBackToCurrentLocation
  },
  {
    provide: SHARE_BET_HOST,
    useValue: shareBetHost
  },
  {
    provide: COMMON_TAX_PAYOUT_DETAIL_INFO,
    useValue: commonTaxPayoutDetailsInfo
  },
  {
    provide: CUSTOM_TAX_PAYOUT_DETAIL_INFO,
    useValue: customTaxPayoutDetailsInfo
  }
];
