import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/sportsbook/lvbetpl/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.lvbet.pl/',
  walletApiUrl: 'https://wallet.lvbet.pl/',
  paymentApiUrl: 'https://payment.lvbet.pl/',
  cmsApiUrl: {
    default: 'https://lvbet.pl/'
  },
  cmsHeadlessApiUrl: 'https://cms.lvbet.pl/',
  shadowStylesUrl: {
    default: 'https://lvbet-static.com/shadow-css/',
    'mobile-web': 'https://m.lvbet.pl/shadow-css/',
    'mobile-capacitor': 'https://m.lvbet.pl/shadow-css/'
  },
  feEnv: {
    baseUrl: 'https://lvbet.pl',
    savePinLoginEndpoint: '/save-pin-login/',
    pinLoginEndpoint: '/pin-login/'
  }
};
